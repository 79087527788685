import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { IconComponent } from '@amaris/lib-highway/components/icon';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@amaris/lib-highway/pipes';
import { TooltipDirective } from '../../functional/tooltip/tooltip.directive';
import { DataStoreService } from '../../../services/data-store/data-store.service';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    IconComponent,
    TooltipDirective,
    TranslatePipe,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent {
  public menuItems: { id: string, link: string, icon: string, label: string, disabled: boolean, tooltip?: string }[] = [
    {
      id: 'btnDashboard',
      link: '/',
      icon: 'customNavDashboard',
      label: 'core.navigation.dashboard',
      tooltip: 'core.navigation.dashboard',
      disabled: false,
    },
    {
      id: 'btnCalendar',
      link: '/calendrier',
      icon: 'customNavCalendar',
      label: 'core.navigation.calendar',
      tooltip: 'core.navigation.calendar',
      disabled: false,
    },
    {
      id: 'btnPatientFolder',
      link: '/patients',
      icon: 'customNavPatientFolder',
      label: 'core.navigation.patients_list',
      tooltip: 'core.navigation.patients_list',
      disabled: false,
    },
    {
      id: 'btnConsultations',
      link: '/consultations',
      icon: 'farRectangleList',
      label: 'core.navigation.consultations_list',
      tooltip: 'core.navigation.consultations_list',
      disabled: false,
    },
    // {
    //   id: 'btnStats',
    //   link: '/finance',
    //   icon: 'customNavStats',
    //   label: 'Finances',
    //   disabled: true,
    //   tooltip: 'core.keywords.available_soon',
    // },
    // {
    //   id: 'btnNavGraph',
    //   link: '/statistiques',
    //   icon: 'customNavGraph',
    //   label: 'Statistiques',
    //   disabled: true,
    //   tooltip: 'core.keywords.available_soon',
    // },
    // {
    //   id: 'btnBoxs',
    //   link: '/stocks',
    //   icon: 'customNavBoxs',
    //   label: 'Gestion des stocks',
    //   disabled: true,
    //   tooltip: 'core.keywords.available_soon',
    // },
    {
      id: 'btnAdministration',
      link: '/administration',
      icon: 'customNavCog',
      label: 'core.navigation.administration',
      disabled: false,
      tooltip: 'core.navigation.motives_and_acts',
    },
  ];

  constructor(
    private readonly dataStoreService: DataStoreService,
  ) {

  }

  closeMobileMenu() {
    this.dataStoreService.setMobileMenuVisible(false);
  }
}
